import React from 'react'

import Layout from '../components/layout'
import SEO from '../components/seo'

const PageNotFound = () => (
  <Layout>
    <SEO title="Page not found" />
    <h1>Page not found</h1>
    <p>This page doesn&#39;t exist.</p>
  </Layout>
)

export default PageNotFound
